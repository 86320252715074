import { Icon } from "@iconify/react";
import classNames from "classnames";

import "./PaginationControls.css";

interface PaginationControlsProps {
  pages: number;
  current: number;
  setCurrent: (newCurrent: number) => void;
}

const SPREAD = 2 as const;

const PaginationControls = ({
  pages,
  current,
  setCurrent,
}: PaginationControlsProps) => {
  const centeredCurrent = Math.min(
    Math.max(current, 1 + SPREAD + 2),
    pages - SPREAD - 2,
  );

  // Shows up to 5 + 2 * SPREAD page number button slots at once, plus 2 for arrows.
  const buttonPageNumbers = Array.from(
    new Set([
      1,
      ...Array.from({ length: SPREAD }).map((_, i) => centeredCurrent - i - 1),
      centeredCurrent,
      ...Array.from({ length: SPREAD }).map((_, i) => centeredCurrent + i + 1),
      pages,
    ]),
  )
    .filter((pageNumber) => 1 <= pageNumber && pageNumber <= pages)
    .sort((a, b) => a - b);

  return (
    <div className="PaginationControls">
      <button
        className="PaginationControls__button"
        disabled={current === 1}
        onClick={() => setCurrent(current - 1)}
      >
        <Icon icon="uil:arrow-left" />
      </button>
      {buttonPageNumbers.map((pageNumber, index, array) => (
        <>
          {!!index && pageNumber - 2 > array[index - 1] && (
            <div className="PaginationControls__ellipsis">⋯</div>
          )}
          {!!index && pageNumber - 2 === array[index - 1] && (
            <button
              className={classNames(
                "PaginationControls__button PaginationControls__button--number",
                {
                  "PaginationControls__button--active":
                    current === pageNumber - 1,
                },
              )}
              key={pageNumber - 1}
              onClick={() => setCurrent(pageNumber - 1)}
            >
              {pageNumber - 1}
            </button>
          )}
          <button
            className={classNames(
              "PaginationControls__button PaginationControls__button--number",
              {
                "PaginationControls__button--active": current === pageNumber,
              },
            )}
            key={pageNumber}
            onClick={() => setCurrent(pageNumber)}
          >
            {pageNumber}
          </button>
        </>
      ))}
      <button
        className="PaginationControls__button"
        disabled={current === pages}
        onClick={() => setCurrent(current + 1)}
      >
        <Icon icon="uil:arrow-right" />
      </button>
    </div>
  );
};

export default PaginationControls;
