import { endOfToday, format, startOfToday } from "date-fns";
import { es } from "date-fns/locale/es";
import { useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";

import useSlackPostMessageMutation from "api/hooks/useSlackPostMessageMutation";
import Stepper from "components/molecules/Stepper";
import { loginSelector } from "store/slices/login";

import "../../Waitlists/NewCampaign/NewCampaign.css";
import "../../Waitlists/NewCampaign/UploadSpreadsheetStep/UploadSpreadsheetStep.css";
import CompletedSuspensionStep from "./CompletedSuspensionStep";
import ConfigureSuspensionStep from "./ConfigureSuspensionStep";
import "./NewSuspension.css";
import VerifySuspensionStep from "./VerifySuspensionStep";

export type ProfessionalOption = {
  label: string;
  value: string;
  centerId: string | null;
};

const DATETIME_LOCAL_FORMAT = "yyyy-LL-dd'T'HH:mm";

const CONFIGURE_SUSPENSION_STEP = 1;
const VERIFY_STEP = 2;
const COMPLETED_STEP = 3;
const STEPS = ["Configurar suspensión", "Verificar pacientes"];

const DEFAULT_START_DATE = format(startOfToday(), DATETIME_LOCAL_FORMAT);
const DEFAULT_END_DATE = format(endOfToday(), DATETIME_LOCAL_FORMAT);
type NewSuspensionStep =
  | typeof CONFIGURE_SUSPENSION_STEP
  | typeof VERIFY_STEP
  | typeof COMPLETED_STEP;

const NewSuspension = () => {
  const { nombreUsuario } = useSelector(loginSelector);
  const ref = useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState<string>(DEFAULT_START_DATE);
  const [endDate, setEndDate] = useState<string>(DEFAULT_END_DATE);
  const { mutate: notifyNewSuspension } = useSlackPostMessageMutation();
  const [selectedProfessional, setSelectedProfessional] =
    useState<ProfessionalOption>();

  const [appointmentsCount, setAppointmentsCount] = useState<number>();

  const setStep = (step: number) => {
    ref.current?.parentElement?.scrollTo({ top: 0 });
    if (enabledSteps.includes(step as NewSuspensionStep)) {
      setCurrentStep(step as NewSuspensionStep);
    }
  };
  const [currentStep, setCurrentStep] = useState<NewSuspensionStep>(
    CONFIGURE_SUSPENSION_STEP,
  );

  const enabledSteps = useMemo(() => {
    if (currentStep === CONFIGURE_SUSPENSION_STEP) {
      return [CONFIGURE_SUSPENSION_STEP];
    }

    return [CONFIGURE_SUSPENSION_STEP, VERIFY_STEP];
  }, [currentStep]);

  const configurationSuccess = ({
    startDate,
    endDate,
    selectedProfessional,
  }: {
    startDate: string;
    endDate: string;
    selectedProfessional: ProfessionalOption;
  }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedProfessional(selectedProfessional);
    setCurrentStep(VERIFY_STEP);
  };

  const onSuccess = (appointmentsCount: number) => {
    notifyNewSuspension({
      text: `:mega: *${nombreUsuario}* ha creado una suspensión para *${selectedProfessional!.label}* entre el ${format(startDate, "PPpp", { locale: es })} y el ${format(endDate, "PPpp", { locale: es })} de tamaño ${appointmentsCount}.`,
      channel: process.env.REACT_APP_SUSPENSION_NOTIFICATIONS_CHANNEL_ID!,
    });
    setAppointmentsCount(appointmentsCount);
    setCurrentStep(COMPLETED_STEP);
  };

  const resetForm = () => {
    setCurrentStep(CONFIGURE_SUSPENSION_STEP);
    setStartDate(DEFAULT_START_DATE);
    setEndDate(DEFAULT_END_DATE);
    setSelectedProfessional(undefined);
  };

  return (
    <div className="NewSuspension">
      <div className="NewCampaign" ref={ref}>
        <h2 className="NewCampaign__title">Suspensión de citas</h2>
        <div className="NewCampaign__content">
          <Stepper
            currentStep={currentStep}
            enabledSteps={enabledSteps}
            onClick={setStep}
            steps={STEPS}
          />
          {currentStep === CONFIGURE_SUSPENSION_STEP && (
            <ConfigureSuspensionStep
              onSubmit={configurationSuccess}
              startDate={startDate}
              endDate={endDate}
            />
          )}
          {currentStep === VERIFY_STEP && !!selectedProfessional && (
            <VerifySuspensionStep
              startDate={new Date(startDate)}
              endDate={new Date(endDate)}
              selectedProfessional={selectedProfessional}
              onSuccess={onSuccess}
            />
          )}
          {currentStep === COMPLETED_STEP &&
            !!selectedProfessional &&
            appointmentsCount !== undefined && (
              <CompletedSuspensionStep
                resetForm={resetForm}
                selectedProfesional={selectedProfessional.label}
                appointmentsCount={appointmentsCount}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default NewSuspension;
