import { Icon, InlineIcon } from "@iconify/react";
import { captureException } from "@sentry/react";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { PropsWithChildren, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { obtenerContenidoMultimedia } from "api/endpoints";
import useVCardQuery from "api/hooks/useVCardQuery";
import { ChatAPIMessage, ChatAPIUserMessage } from "api/types/responses";
import Loader from "components/Loader";
import { scrambleMulti } from "components/Scrambler/scramblers";
import Tooltip from "components/Tooltip";
import { getSeenData, formatWhatsappMsg } from "helpers/mensajes";
import { esCero } from "helpers/permisos";
import useWhatsappLink from "hooks/useWhatsappLink";
import { ceroSelector } from "store/slices/cero";
import { loginSelector } from "store/slices/login";
import { scramblerSelector } from "store/slices/scrambler";

import "./MensajeWhatsapp.css";

const extensionesImagenes = ["png", "jpg", "jpeg", "gif", "bmp"];
const tokenAdjunto = "ATTACHMENT:";

interface MensajeWhatsappProps {
  mensaje: ChatAPIMessage;
  mensajes: ChatAPIMessage[];
  posicion: number;
}

const MensajeWhatsapp = ({
  mensaje,
  mensajes,
  posicion,
}: MensajeWhatsappProps) => {
  const ts = parseISO(mensaje.timestamp);
  const fechaMensaje = format(ts, "d 'de' MMMM 'de' yyyy", { locale: es });
  const diaMensaje = format(ts, "d");
  const diaMensajeAnterior =
    posicion > 0 && format(parseISO(mensajes[posicion - 1].timestamp), "d");
  const mostrarFecha = posicion === 0 || diaMensaje !== diaMensajeAnterior;
  const mensajeEsDeHumano = mensaje.type !== "bot";
  const { cuenta } = useSelector(loginSelector);
  const { debugging } = useSelector(ceroSelector);

  const horaMensaje = format(
    ts,
    esCero(cuenta) && debugging ? "HH:mm:ss" : "HH:mm",
  );

  let componenteMensaje;
  switch (mensaje.message) {
    case "MEDIAIMAGEURL":
      componenteMensaje = <MensajeImagen mensaje={mensaje} />;
      break;
    case "MEDIAAUDIOURL":
      componenteMensaje = <MensajeAudio mensaje={mensaje} />;
      break;
    case "MEDIAFILEURL":
      componenteMensaje = <MensajeArchivo mensaje={mensaje} />;
      break;
    case "MEDIAVCARDURL":
      componenteMensaje = <MensajeContacto mensaje={mensaje} />;
      break;
    case "MEDIAVIDEOURL":
      componenteMensaje = <MensajeVideo mensaje={mensaje} />;
      break;
    default:
      componenteMensaje = <MensajeTexto mensaje={mensaje} />;
  }

  return (
    <div className="MensajeWhatsapp">
      {mostrarFecha && <Fecha fecha={fechaMensaje} />}
      <Globo esDeHumano={mensajeEsDeHumano}>
        {componenteMensaje}
        <MessageFooter
          hora={horaMensaje}
          seenData={getSeenData(mensaje, esCero(cuenta) && debugging)}
        />
        {mensajeEsDeHumano && mensaje.tag && (
          <div
            className={classNames({
              MensajeWhatsapp__tag: true,
              "MensajeWhatsapp__tag--visible": esCero(cuenta) && debugging,
            })}
          >
            🤖 {mensaje.tag}
          </div>
        )}
      </Globo>
    </div>
  );
};

interface FechaProps {
  fecha: string;
}

const Fecha = ({ fecha }: FechaProps) => (
  <div className="MensajeWhatsapp__fecha">{fecha}</div>
);

interface GloboProps {
  esDeHumano: boolean;
}

const Globo = ({ esDeHumano, children }: PropsWithChildren<GloboProps>) => (
  <div
    className={classNames({
      MensajeWhatsapp__globo: true,
      "MensajeWhatsapp__globo--saliente": !esDeHumano,
      "MensajeWhatsapp__globo--entrante": esDeHumano,
    })}
  >
    {children}
  </div>
);

interface MensajeMediaProps {
  mensaje: ChatAPIMessage;
}

interface MensajeTextoProps {
  mensaje: ChatAPIMessage;
}

const MensajeTexto = ({ mensaje }: MensajeTextoProps) => {
  const { terminos, scrambled } = useSelector(scramblerSelector);

  return (
    <span className="MensajeWhatsapp__texto">
      {mensaje.message.indexOf(tokenAdjunto) >= 0 ? (
        <MensajeConAdjunto mensaje={mensaje.message} />
      ) : (
        <span>
          {formatWhatsappMsg(
            scrambled
              ? scrambleMulti(mensaje.message, terminos)
              : mensaje.message,
          )}
        </span>
      )}
    </span>
  );
};

const MensajeImagen = ({ mensaje }: MensajeMediaProps) => {
  const [urlImagen, setUrlImagen] = useState("");
  const [huboError, setHuboError] = useState(false);

  const verImagen = async () => {
    try {
      const data = await obtenerContenidoMultimedia(
        (mensaje as ChatAPIUserMessage).answer_id,
      );
      setUrlImagen(data.data.data.url);
    } catch (err) {
      captureException(err);
      setHuboError(true);
    }
  };

  if (huboError) {
    return (
      <p className="MensajeWhatsapp__error_multimedia">Imagen no disponible</p>
    );
  }

  return urlImagen ? (
    <img
      className="MensajeWhatsapp__imagen"
      src={urlImagen}
      alt="Imagen para descargar"
    />
  ) : (
    <button
      className="MensajeWhatsapp__placeholder_imagen"
      onClick={() => verImagen()}
      title="Ver imagen"
    >
      <p className="MensajeWhatsapp__texto_placeholder_imagen">
        <InlineIcon icon="mdi:image" /> Ver imagen
      </p>
    </button>
  );
};

const MensajeAudio = ({ mensaje }: MensajeMediaProps) => {
  const [urlAudio, setUrlAudio] = useState("");
  const [huboError, setHuboError] = useState(false);
  const [cargandoAudio, setCargandoAudio] = useState(false);

  const verAudio = async () => {
    setCargandoAudio(true);
    try {
      const data = await obtenerContenidoMultimedia(
        (mensaje as ChatAPIUserMessage).answer_id,
      );
      setUrlAudio(data.data.data.url);
      setCargandoAudio(false);
    } catch (err) {
      captureException(err);
      setHuboError(true);
      setCargandoAudio(false);
    }
  };

  if (huboError) {
    return (
      <p className="MensajeWhatsapp__error_multimedia">Audio no disponible</p>
    );
  }

  return urlAudio ? (
    <audio
      className="MensajeWhatsapp__audio"
      src={urlAudio}
      controls
      // TODO(a11y): captions/transcripts
    />
  ) : (
    <button
      onClick={() => verAudio()}
      className="MensajeWhatsapp__placeholder_audio"
    >
      <InlineIcon
        className={classNames({
          MensajeWhatsapp__placeholder_icono_reproducir_audio: true,
          "MensajeWhatsapp__placeholder_icono_reproducir_audio--cargando":
            cargandoAudio,
        })}
        icon={cargandoAudio ? "mdi:loading" : "mdi:play"}
      />
      <div className="MensajeWhatsapp__trackbar"></div>
    </button>
  );
};

const MensajeVideo = ({ mensaje }: MensajeMediaProps) => {
  const [urlImagen, setUrlImagen] = useState("");
  const [huboError, setHuboError] = useState(false);

  const verImagen = async () => {
    try {
      const data = await obtenerContenidoMultimedia(
        (mensaje as ChatAPIUserMessage).answer_id,
      );
      setUrlImagen(data.data.data.url);
    } catch (err) {
      captureException(err);
      setHuboError(true);
    }
  };

  if (huboError) {
    return (
      <p className="MensajeWhatsapp__error_multimedia">Video no disponible</p>
    );
  }

  return urlImagen ? (
    <video
      className="MensajeWhatsapp__video"
      src={urlImagen}
      // TODO(a11y): captions/transcripts
      controls
    />
  ) : (
    <button
      className="MensajeWhatsapp__placeholder_imagen"
      onClick={() => verImagen()}
      title="Ver imagen"
    >
      <p className="MensajeWhatsapp__texto_placeholder_imagen">
        <InlineIcon icon="mdi:video" /> Ver video
      </p>
    </button>
  );
};

const MensajeArchivo = ({ mensaje }: MensajeMediaProps) => {
  const [huboError, setHuboError] = useState(false);

  const descargarArchivo = async () => {
    try {
      const data = await obtenerContenidoMultimedia(
        (mensaje as ChatAPIUserMessage).answer_id,
      );
      const elemento = document.createElement("a");
      elemento.setAttribute("target", "_blank");
      elemento.setAttribute("href", data.data.data.url);
      elemento.style.display = "none";
      document.body.appendChild(elemento);
      elemento.click();
      document.body.removeChild(elemento);
    } catch (err) {
      captureException(err);
      setHuboError(true);
    }
  };

  if (huboError) {
    return (
      <p className="MensajeWhatsapp__error_multimedia">Archivo no disponible</p>
    );
  }

  return (
    <button
      className="MensajeWhatsapp__boton_descargar_archivo"
      onClick={descargarArchivo}
    >
      Descargar Archivo
      <InlineIcon
        className="MensajeWhatsapp__icono_descargar_archivo"
        icon="mdi:download-circle-outline"
      />
    </button>
  );
};

const MensajeContacto = ({ mensaje }: MensajeMediaProps) => {
  const [enabled, setEnabled] = useState(false);
  const { data, error, isError, isLoading } = useVCardQuery(
    (mensaje as ChatAPIUserMessage).answer_id,
    enabled,
  );
  const whatsappLink = useWhatsappLink(data?.phone?.replace(/[^0-9]/g, ""));

  useEffect(() => {
    if (isError) {
      captureException(error);
    }
  }, [isError, error]);

  if (isError) {
    return (
      <p className="MensajeWhatsapp__error_multimedia">Archivo no disponible</p>
    );
  }

  if (data?.name) {
    return (
      <div className="MensajeWhatsapp__contenedor_contacto">
        <InlineIcon
          className="MensajeWhatsapp__icono_contacto"
          icon="mdi:person-circle"
        />
        <p>{data?.name}</p>
        {!!data?.phone && (
          <a href={whatsappLink} target="_blank" rel="noreferrer noopener">
            {data?.phone}
          </a>
        )}
      </div>
    );
  }

  return (
    <button
      className="MensajeWhatsapp__boton_contacto"
      onClick={() => setEnabled(true)}
    >
      {isLoading ? (
        <Loader color="gray" />
      ) : (
        <InlineIcon
          className="MensajeWhatsapp__icono_contacto"
          icon="mdi:person-circle"
        />
      )}
      Haga click para ver contacto
    </button>
  );
};

interface MensajeConAdjuntoProps {
  mensaje: string;
}

const MensajeConAdjunto = ({ mensaje }: MensajeConAdjuntoProps) => {
  const { terminos, scrambled } = useSelector(scramblerSelector);
  const inicioAdjunto = mensaje.indexOf(tokenAdjunto) + tokenAdjunto.length;
  const substringAdjunto = mensaje.substring(inicioAdjunto);
  const finAdjunto =
    substringAdjunto.search(/\s/) > 0
      ? substringAdjunto.search(/\s/)
      : substringAdjunto.length;
  let urlArchivo = substringAdjunto.substring(0, finAdjunto);
  if (!urlArchivo.startsWith("http")) {
    urlArchivo = `https://${urlArchivo}`;
  }
  const mensajeSinAdjunto =
    mensaje.substring(0, mensaje.indexOf(tokenAdjunto) - 1) +
    substringAdjunto.substring(finAdjunto);
  const nombreArchivo = decodeURIComponent(
    urlArchivo.substring(urlArchivo.lastIndexOf("/") + 1),
  );
  const extensionArchivo = nombreArchivo.substring(
    nombreArchivo.lastIndexOf(".") + 1,
  );

  return (
    <div>
      {extensionesImagenes.includes(extensionArchivo) ? (
        <a
          target="_blank"
          rel="noreferrer noopener"
          className="MensajeWhatsapp__link_archivo"
          href={urlArchivo}
        >
          <img
            src={urlArchivo}
            className="MensajeWhatsapp__miniatura_imagen"
            alt="imagen indicación"
          />
        </a>
      ) : (
        <a
          target="_blank"
          rel="noreferrer noopener"
          className="MensajeWhatsapp__link_archivo"
          href={urlArchivo}
        >
          <div className="MensajeWhatsapp__icono_pdf">PDF</div>
          <div className="MensajeWhatsapp__nombre_archivo">
            {scrambled ? scrambleMulti(nombreArchivo, terminos) : nombreArchivo}
          </div>
          <div className="MensajeWhatsapp__icono_link">
            <Icon icon="mdi:arrow-down-bold" />
          </div>
        </a>
      )}
      {mensajeSinAdjunto.length > 0 &&
        formatWhatsappMsg(
          scrambled
            ? scrambleMulti(mensajeSinAdjunto, terminos)
            : mensajeSinAdjunto,
        )}
    </div>
  );
};

interface MessageFooterProps {
  hora: string;
  seenData?: { messages: string[]; suffix: string; icon: string };
}

const MessageFooter = ({ hora, seenData }: MessageFooterProps) => {
  return (
    <div className="MessageFooter">
      <span className="MessageFooter__time">{hora}</span>
      {seenData && <Visto {...seenData} />}
    </div>
  );
};

interface VistoProps {
  messages: string[];
  suffix: string;
  icon: string;
}

const Visto = ({ messages, icon, suffix }: VistoProps) => (
  <Tooltip text={messages.join("\n")} disabled={messages.length === 0}>
    <InlineIcon
      className={classNames(
        "MensajeWhatsapp__icono_visto",
        suffix && `MensajeWhatsapp__icono_visto--${suffix}`,
      )}
      icon={icon}
    />
  </Tooltip>
);

export default MensajeWhatsapp;
