import { InlineIcon } from "@iconify/react";
import { captureException, setUser as setSentryUser } from "@sentry/react";
import { HttpStatusCode, isAxiosError } from "axios";
import classNames from "classnames";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import useLoginMutation from "api/hooks/useLoginMutation";
import logo from "assets/images/logo-feedback.svg";
import Loader from "components/Loader";
import { AuthRequest } from "feedback-api";
import { guardaTiposEncuestas } from "store/slices/encuestas";
import { guardaDatosSesion } from "store/slices/login";

import "./Login.css";

const MFA_COOLDOWN_INTERVAL_SECONDS = 60;

const Login = () => {
  const [auth, setAuth] = useState({
    username: "",
    password: "",
    otp: null,
  });

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState<string>();
  const dispatch = useDispatch();

  const [requestMFA, setRequestMFA] = useState(false);
  const [isMFACooldown, setMFACooldown] = useState(false);
  const [MFACooldownSeconds, setMFACooldownSeconds] = useState(0);
  const intervalIdRef = useRef<NodeJS.Timeout>();
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState<
    string | null | undefined
  >(null);

  const cambiarVariable =
    (variable: "username" | "password" | "otp") =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAuth({ ...auth, [variable]: e.target.value });
    };

  const { mutate, isPending, isError } = useLoginMutation();

  const login = (e: React.FormEvent) => {
    e.preventDefault();
    handleLogin(auth);
  };

  const requestNewOtp = () => {
    handleLogin({ ...auth, otp: undefined });
    setMFACooldown(true);
    setMFACooldownSeconds(MFA_COOLDOWN_INTERVAL_SECONDS);
  };

  useEffect(() => {
    if (isMFACooldown) {
      const id = setInterval(() => setMFACooldownSeconds((c) => c - 1), 1000);
      intervalIdRef.current = id;
      return () => {
        clearInterval(id);
      };
    }
  }, [isMFACooldown]);

  useEffect(() => {
    if (MFACooldownSeconds <= 0) {
      setMFACooldown(false);
      clearInterval(intervalIdRef.current);
    }
  }, [MFACooldownSeconds]);

  const handleLogin = (auth: AuthRequest) => {
    mutate(auth, {
      onSuccess: (data) => {
        if (data.type === "MFA_REQUIRED") {
          setRequestMFA(true);
          setMFACooldown(true);
          setMFACooldownSeconds(MFA_COOLDOWN_INTERVAL_SECONDS);
          setMaskedPhoneNumber(data.masked_phone_number);
          return;
        }
        setSentryUser({ username: auth.username });
        // @ts-expect-error missing API type annotations for polls
        dispatch(guardaDatosSesion({ ...data, cuenta: auth.username }));
        // @ts-expect-error missing API type annotations for polls
        dispatch(guardaTiposEncuestas(data));
      },
      onError: (e) => {
        if (
          isAxiosError(e) &&
          e.response?.status === HttpStatusCode.Unauthorized
        ) {
          setError(
            e.response.data.detail === "Incorrect OTP"
              ? "Código incorrecto."
              : "Usuario o contraseña incorrectos.",
          );
        } else if (
          isAxiosError(e) &&
          e.response?.status === HttpStatusCode.BadRequest
        ) {
          setError(
            e.response.data.detail ===
              "User does not have a phone number configured"
              ? "Esta cuenta no tiene un número de teléfono configurado para la verificación de dos pasos. Por favor, contacta a soporte@cero.ai o al equipo de Customer Success para que pueda ayudarte."
              : "Servicio no disponible en estos momentos. Por favor, intenta de nuevo en unos minutos.",
          );
        } else {
          captureException(e);
          setError(
            "Servicio no disponible en estos momentos. Por favor, intenta de nuevo en unos minutos.",
          );
        }
      },
    });
  };

  return (
    <div
      className={classNames({
        Login: true,
        "Login--cargando": isPending,
      })}
    >
      <div className="Login__contenedor_logo">
        <img className="Login__logo" src={logo} alt="Logo Cero Feedback" />
      </div>
      <form className="Login__form" onSubmit={login}>
        <h1 className="Login__instruccion">Inicia sesión en tu cuenta</h1>
        {requestMFA ? (
          <>
            <p className="Login__detalle">
              Ingresa el código de 6 dígitos que acabamos de enviar por SMS a tu
              teléfono terminado en {maskedPhoneNumber || "..."}
            </p>
            <div className="Login__campo">
              <label htmlFor="login_otp" className="Login__label">
                Código
              </label>
              <input
                className="Login__input"
                type="text"
                inputMode="numeric"
                minLength={6}
                maxLength={6}
                onChange={cambiarVariable("otp")}
                id="login_otp"
                autoFocus
                required
              />
              {isError && (
                <p className="Login__error">
                  <InlineIcon
                    className="Login__icono_error"
                    icon="uil:exclamation-triangle"
                  />
                  {error}
                </p>
              )}
            </div>
            <button
              className="Login__request_new_otp"
              type="button"
              disabled={MFACooldownSeconds > 0}
              onClick={requestNewOtp}
            >
              {isMFACooldown
                ? `Podrás pedir otro código en ${MFACooldownSeconds} segundos`
                : "Enviar otro código"}
            </button>
          </>
        ) : (
          <>
            <div className="Login__campo">
              <label htmlFor="login_usuario" className="Login__label">
                Nombre de usuario
              </label>
              <input
                className="Login__input"
                type="text"
                onChange={cambiarVariable("username")}
                id="login_usuario"
                autoFocus
                required
                autoComplete="username"
              />
            </div>
            <div className="Login__campo">
              <label htmlFor="login_password" className="Login__label">
                Contraseña
              </label>
              <div className="Login__input_wrapper">
                <input
                  className="Login__input"
                  type={isPasswordVisible ? "text" : "password"}
                  onChange={cambiarVariable("password")}
                  id="login_password"
                  autoComplete="current-password"
                  required
                />
                <button
                  className="Login__toggle"
                  type="button"
                  aria-label={
                    isPasswordVisible
                      ? "Ocultar contraseña."
                      : "Mostrar contraseña en texto plano. Advertencia: esto mostrará tu contraseña en la pantalla."
                  }
                  onClick={() => setPasswordVisible(!isPasswordVisible)}
                >
                  <InlineIcon
                    icon={isPasswordVisible ? "uil:eye-slash" : "uil:eye"}
                  />
                </button>
              </div>
              {isError && (
                <p className="Login__error">
                  <InlineIcon
                    className="Login__icono_error"
                    icon="uil:exclamation-triangle"
                  />
                  {error}
                </p>
              )}
            </div>
          </>
        )}
        <button className="Login__boton" type="submit" disabled={isPending}>
          {isPending ? <Loader /> : "Ingresar"}
        </button>
      </form>
      <footer className="Login__footer">
        © Cero {format(Date.now(), "yyyy")}
      </footer>
    </div>
  );
};

export default Login;
