import { Icon, InlineIcon } from "@iconify/react";
import classNames from "classnames";
import { formatISO } from "date-fns";
import { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";

import useCreateSuspensionMutation from "api/hooks/useCreateSuspensionMutation";
import useGetSuspensionCandidatesQuery from "api/hooks/useGetSuspensionCandidatesQuery";
import Loader from "components/Loader";
import Tooltip from "components/Tooltip";
import Button from "components/atoms/Button";
import Table from "components/atoms/Table";
import { ErrorCode } from "feedback-api";
import { addToast } from "store/slices/toasts";
import { humanizeFullDate } from "utils/date";

import { ProfessionalOption } from "../NewSuspension";
import ConfirmModal from "./ConfirmModal/ConfirmModal";
import SuspensionMessagePreview from "./SuspensionMessagePreview/SuspensionMessagePreview";
import "./VerifySuspensionStep.css";

interface VerifyAndSendSuspensionStepProps {
  startDate: Date;
  endDate: Date;
  selectedProfessional: ProfessionalOption;
  onSuccess: (appointmentsCount: number) => void;
}

const VerifyAndSendSuspensionStep = ({
  startDate,
  endDate,
  selectedProfessional,
  onSuccess,
}: VerifyAndSendSuspensionStepProps) => {
  const dispatch = useDispatch();

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const {
    data: suspensionCandidatesData,
    isPending: isPendingGetSuspensionCandidates,
    error,
    isError,
  } = useGetSuspensionCandidatesQuery({
    professionalId: selectedProfessional.value,
    centerId: selectedProfessional.centerId,
    startDate: formatISO(startDate),
    endDate: formatISO(endDate),
  });
  const { mutate: createSuspension, isPending: isPendingCreateSuspension } =
    useCreateSuspensionMutation();

  const suspensionName = () => {
    const name = "Suspensión para " + selectedProfessional?.label;
    if (name.length > 64) {
      return name.substring(0, 64);
    }

    return name;
  };

  const getErrorMessageForCode = (errorCode: ErrorCode) =>
    ({
      [ErrorCode.ConnectionError]:
        "Lo sentimos, no pudimos conectarnos con la agenda en este momento. Por favor, intenta de nuevo más tarde.",
      [ErrorCode.GenericError]:
        "Hubo un problema al obtener las citas de la agenda. Por favor, intenta de nuevo más tarde.",
    })[errorCode];

  const selectAllRef = useRef<HTMLInputElement>(null);
  const [excludedCandidateIds, setExcludedCandidateIds] = useState(
    new Set<string>(),
  );

  const toggleAllSelectableCandidates = () => {
    if (excludedCandidateIds.size === 0) {
      setExcludedCandidateIds(
        new Set(
          suspensionCandidatesData
            ?.filter((candidate) => !candidate.notified && candidate.has_phone)
            .map((candidate) => candidate.id),
        ),
      );
    } else {
      setExcludedCandidateIds(new Set());
    }
  };

  const toggleCandidate = (id: string) => {
    const newExcludedCandidateIds = excludedCandidateIds.symmetricDifference(
      new Set([id]),
    );

    setExcludedCandidateIds(newExcludedCandidateIds);
  };

  const selectableCandidatesCount =
    suspensionCandidatesData?.filter(
      (candidate) => !candidate.notified && candidate.has_phone,
    ).length ?? 0;
  const selectedCandidatesCount =
    selectableCandidatesCount - excludedCandidateIds.size;

  const pluralize = (word: string, count: number) => {
    return word + (count === 1 ? "" : "s");
  };

  const footer = [
    "Mostrando",
    " ",
    suspensionCandidatesData?.length,
    " ",
    pluralize("paciente", suspensionCandidatesData?.length ?? 0),
    " (",
    selectedCandidatesCount,
    " ",
    pluralize("paciente", selectedCandidatesCount),
    " ",
    pluralize("seleccionado", selectedCandidatesCount),
    ")",
  ].join("");

  useEffect(() => {
    if (selectAllRef.current === null) {
      return;
    }
    selectAllRef.current.indeterminate =
      excludedCandidateIds.size > 0 &&
      excludedCandidateIds.size < selectableCandidatesCount;
  }, [excludedCandidateIds, selectableCandidatesCount]);

  const handleCreateSuspension = () => {
    createSuspension(
      {
        start: formatISO(startDate),
        end: formatISO(endDate),
        name: suspensionName(),
        professional_id: selectedProfessional.value,
        professional_name: selectedProfessional.label,
        excluded_appointment_ids: Array.from(excludedCandidateIds),
        center_id: selectedProfessional.centerId,
      },
      {
        onSuccess: () => onSuccess(selectedCandidatesCount),
        onError: (error) => {
          dispatch(
            addToast({
              message: getErrorMessageForCode(error.error_code),
              type: "error",
            }),
          );
        },
      },
    );
  };

  const onConfirmSuspension = () => {
    setConfirmModalOpen(false);
    handleCreateSuspension();
  };

  const tableHeaders =
    suspensionCandidatesData && suspensionCandidatesData.length > 0
      ? Object.keys(suspensionCandidatesData[0].appointment_display_data)
      : [];

  const suspendableAppointments = suspensionCandidatesData?.filter(
    (candidate) => candidate.appointment_data,
  );

  return (
    <div className="UploadSpreadsheetStep">
      <div className="UploadSpreadsheetStep__section">
        <h3 className="UploadSpreadsheetStep__title">Verificar pacientes</h3>
        <p className="UploadSpreadsheetStep__copy">
          Revisa el listado de pacientes de acuerdo a los criterios definidos en
          el paso anterior. En esta etapa, puedes desmarcar a los pacientes que
          no desees incluir en el envío de mensajes.
        </p>
      </div>
      <div className="VerifySuspension__main_row">
        <div className="VerifySuspension__section">
          <div className="VerifySuspension__card">
            <div className="VerifySuspension__card_info">
              <div className="VerifySuspension__card_info_title">
                <Icon icon="uil:calendar-alt" />
                <span>Fecha de inicio</span>
              </div>
              <span>{humanizeFullDate(startDate)}</span>
            </div>
            <div className="VerifySuspension__card_info">
              <div className="VerifySuspension__card_info_title">
                <Icon icon="uil:calendar-alt" />
                <span>Fecha de término</span>
              </div>
              <span>{humanizeFullDate(endDate)}</span>
            </div>
            <div className="VerifySuspension__card_info">
              <div className="VerifySuspension__card_info_title">
                <Icon icon="uil:user-square" />
                <span>Profesional</span>
              </div>
              <span>{selectedProfessional.label}</span>
            </div>
          </div>
          {isPendingGetSuspensionCandidates ? (
            <div>Cargando citas a suspender... </div>
          ) : isError ? (
            <div>{getErrorMessageForCode(error.error_code)}</div>
          ) : suspensionCandidatesData.length === 0 ? (
            <div>No se encontraron citas para suspender</div>
          ) : (
            <div className="VerifySuspensionStep__scroller">
              <Table className="VerifySuspensionStep__table">
                <thead>
                  <tr>
                    <th>
                      <input
                        ref={selectAllRef}
                        type="checkbox"
                        checked={selectedCandidatesCount !== 0}
                        onChange={toggleAllSelectableCandidates}
                      />
                    </th>
                    {tableHeaders.map((header) => (
                      <th key={header}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {suspensionCandidatesData.map((item, index) => (
                    <tr
                      key={index}
                      className={classNames({
                        "VerifySuspensionStep__table_row--disabled":
                          item.notified || !item.has_phone,
                      })}
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={
                            !excludedCandidateIds.has(item.id) &&
                            !item.notified &&
                            item.has_phone
                          }
                          onChange={() => toggleCandidate(item.id)}
                          disabled={item.notified || !item.has_phone}
                        />
                      </td>
                      {tableHeaders.map((header, index) => (
                        <td key={header}>
                          {index === 0 && item.notified ? (
                            <Tooltip text="Cita suspendida: este paciente ya fue contactado.">
                              <InlineIcon icon="uil:padlock" />{" "}
                              {item.appointment_display_data[header]}
                            </Tooltip>
                          ) : index === 0 && !item.has_phone ? (
                            <Tooltip text="Paciente incontactable: el número de teléfono proporcionado no es válido.">
                              <InlineIcon icon="uil:android-phone-slash" />{" "}
                              {item.appointment_display_data[header]}
                            </Tooltip>
                          ) : (
                            item.appointment_display_data[header]
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={tableHeaders.length + 1}>{footer}</th>
                  </tr>
                </tfoot>
              </Table>
              <ConfirmModal
                isOpen={isConfirmModalOpen}
                appointmentsCount={
                  selectableCandidatesCount - excludedCandidateIds.size
                }
                professionalName={selectedProfessional.label}
                start={startDate}
                end={endDate}
                onConfirm={onConfirmSuspension}
                onCancel={() => setConfirmModalOpen(false)}
              />
            </div>
          )}
        </div>
        {!!suspendableAppointments?.length && (
          <SuspensionMessagePreview
            candidateAppointments={suspendableAppointments}
          />
        )}
      </div>
      <Button
        onClick={() => setConfirmModalOpen(true)}
        disabled={!(selectableCandidatesCount > excludedCandidateIds.size)}
      >
        Notificar pacientes
      </Button>

      <ReactModal
        isOpen={isPendingCreateSuspension}
        overlayClassName="Loader__backdrop"
        className="Dummy_class"
      >
        <Loader />
      </ReactModal>
    </div>
  );
};

export default VerifyAndSendSuspensionStep;
