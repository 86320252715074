import { wrapCreateBrowserRouter } from "@sentry/react";
import {
  LoaderFunctionArgs,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";

import NewSuspension from "components/Feedback/Suspensions/NewSuspension";
import NotFound from "components/pages/NotFound";

import ExportacionAvanzada from "../ExportacionAvanzada";
import Alerts, { AlertsTopbar } from "../Feedback/Alerts";
import Dashboard, { DashboardTopbar } from "../Feedback/Dashboard";
import Respuestas, { BotonEnviador } from "../Feedback/Respuestas";
import Chat from "../Feedback/Respuestas/Chat";
import TablaRespuestas from "../Feedback/Respuestas/TablaRespuestas";
import Search, { SearchTopbar } from "../Feedback/Search";
import ServiceSelector from "../Feedback/Topbar/ServiceSelector";
import Tutoriales from "../Feedback/Tutoriales";
import VideoContainer from "../Feedback/Tutoriales/VideoContainer";
import VideoPlaceholder from "../Feedback/Tutoriales/VideoPlaceholder";
import Uso from "../Feedback/Uso";
import Waitlists, { WaitlistsTopbar } from "../Feedback/Waitlists";
import Campaigns from "../Feedback/Waitlists/Campaigns";
import NewCampaign from "../Feedback/Waitlists/NewCampaign";
import Root from "../Root";

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    Component: Root,
    children: [
      {
        path: "respuestas",
        Component: Respuestas,
        children: [
          {
            index: true,
            Component: TablaRespuestas,
            handle: { TopbarComponent: BotonEnviador },
          },
          { path: ":serviceId/:patientId", Component: Chat },
        ],
        handle: { TopbarComponent: ServiceSelector },
      },
      {
        path: "alertas",
        Component: Alerts,
        handle: { TopbarComponent: AlertsTopbar },
      },
      {
        path: "alertas/:serviceId/:patientId",
        Component: Alerts,
        handle: { TopbarComponent: AlertsTopbar },
      },
      {
        path: "busqueda",
        Component: Search,
        handle: { TopbarComponent: SearchTopbar },
      },
      {
        path: "dashboard",
        Component: Dashboard,
        handle: { TopbarComponent: DashboardTopbar },
      },
      { path: "exportar", Component: ExportacionAvanzada },
      { path: "uso", Component: Uso },
      { path: "suspensions", Component: NewSuspension },

      {
        path: "listas-espera",
        Component: Waitlists,
        children: [
          { index: true, Component: Campaigns },
          { path: "campanyas/nueva", Component: NewCampaign },
        ],
        handle: { TopbarComponent: WaitlistsTopbar },
      },
      {
        path: "tutoriales",
        Component: Tutoriales,
        children: [
          { index: true, Component: VideoPlaceholder },
          { path: ":videoId", Component: VideoContainer },
        ],
      },

      // Legacy route redirects
      {
        index: true,
        loader: () => {
          throw redirect(`/respuestas`);
        },
      },
      {
        path: "chat/:serviceId/:patientId",
        loader: ({ params }: LoaderFunctionArgs) => {
          throw redirect(`/respuestas/${params.serviceId}/${params.patientId}`);
        },
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
